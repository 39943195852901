import { createTheme } from "@swagup-com/react-ds-components";
import { ThemeProvider, Button, Typography, Grid, Box } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import productService from "./services/product";
import configurationService from "./services/configurationService";

const theme = createTheme();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

async function App() {
  const config = await configurationService();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        Version: {config.version}
        <ProductHealth />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const fiveMinutes = 5 * 60 * 1000;

function ProductHealth() {
  const { status, isFetching, refetch } = useQuery(
    "productHealth",
    productService.checkHealth,
    { refetchInterval: fiveMinutes }
  );

  return (
    <HealthCheck
      title="Product Service"
      status={isFetching ? "loading" : status}
      onCheck={refetch}
    />
  );
}

const statusColor = {
  success: "success.main",
  error: "error.main",
  loading: "neutral.400",
};

const statusText = {
  success: "Success",
  error: "Failure",
  loading: "Loading...",
};

function HealthCheck({ title, status, onCheck }) {
  const color = statusColor[status];
  const text = statusText[status];

  return (
    <Box
      sx={{
        m: 4,
        p: 4,
        borderColor: color,
        borderWidth: 0.25,
        borderStyle: "solid",
        borderRadius: 4,
        maxWidth: 220,
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="body2">
          Status:{" "}
          <Typography component="span" sx={{ color }}>
            {text}
          </Typography>
        </Typography>
        <Button
          onClick={onCheck}
          disabled={status === "loading"}
          variant="contained"
          size="small"
          sx={{
            py: 3,
            px: 2,
            height: "unset",
            borderRadius: 2,
          }}
        >
          Check
        </Button>
      </Grid>
    </Box>
  );
}

export default App;
