import configurationService from "./configurationService";

const productService = {
  checkHealth: async () => {
    const config = await configurationService();
    fetch(config.productServiceUrl).then((response) => response.text());
  },
};

export default productService;
