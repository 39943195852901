async function configurationService() {
  const response = await fetch("/config.json", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return response.json();
}

export default configurationService;
